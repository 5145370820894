
import * as React from "react"
import Layout from "../components/layout"


const OwnerRepPage = () => {
    return (
        <Layout pageTitle="Owner Representation">
            <p>All construction projects essentially involve a relationship between three parties: the Owner, the Architect, and the General Contractor. </p>

            <p>Architects and Contractors have extensive experience, and often communicate in a language that may seem foreign.  Unfortunately, most Owners have very little experience in the building industry and are hence at an extreme disadvantage. Construction projects are complex processes, fraught with a multitude of components that can, if not managed properly, significantly effect the outcome and increase overall cost.</p>

            <p>It is extremely important for an Owner to be fully represented and informed on all project-related issues as they arise. Time critical decisions often need to be made that impact the costs, quality, completion date, and the eventual outcome of a project.</p>

            <p>We leverage our extensive experience in the building industry to fully “represent” the Owner and their best interests, from the beginning of a project to completion of the punch list and permit sign offs at the very end.</p>

            <p>The sooner Thornley Associates gets involved in a project and become the Owner’s Representative advocate, the more control that Owners have over budget, completion date, quality - and the project’s general success.</p>
            <h2>Our Owners Representation services include:</h2>


            <ul>
                <li>★Hiring a General Contractor and/or Architect.</li>
                <li>★Monitoring the design development</li>
                <li>★Monitoring associated construction cost estimates</li>
                <li>★Assisting in acquisition of planning and building approvals</li>
                <li>★Organizing and running monthly project meetings</li>
                <li>★Reviewing and approving subsequent meeting minutes</li>
                <li>★Making frequent job site visits with follow up progress reports</li>
                <li>★Reviewing and approving General Contractor monthly CSI Billings</li>
                <li>★Reviewing and approving Unconditional and Conditional Lien Releases</li>
                <li>★Reviewing and approving Change Orders</li>
                <li>★Reviewing and approving substitutions and project schedule</li>
                <li>★Monitoring project close out and “Punch List” completion</li>
                <li>★Monitoring submission of “As Built Plans” and Project Manuals</li>
                <li>★Monitoring final Building and Planning approvals</li>

            </ul>



            <h2>Owners Representation services may also include:</h2>

            <p>★Estate Property Management (broken link)  </p>

            <p>★RFP + Response Management (broken link) </p>

        </Layout>
    )
}

export default OwnerRepPage